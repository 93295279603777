
          @use 'sass:math';
          $env: 'production';
          @import '/var/build/src/styles/utils/index';
        






















































































































.prices__heading {
  border-bottom: 1px solid $c-gray-lighter;
}

// Criteria
.prices-criteria {
  ::v-deep {
    .flexible-keylist__item:nth-last-child(1) {
      padding-bottom: 0;
      border-bottom: 0;
    }

    .flexible-keylist__item__title {
      margin-bottom: $spacing * 0.5;
      color: $c-mustard;
    }
  }

  @include mq(l) {
    ::v-deep {
      .flexible-keylist__intro {
        width: col(4, 12);
        margin-right: col(1, 12);
      }

      .flexible-keylist__item:nth-last-child(2) {
        padding-bottom: 0;
        border-bottom: 0;
      }
    }
  }
}

// Realizations slider
.prices-realizations {
  ::v-deep {
    .flexible-slider-container {
      height: calc(calc(calc(80vw / 3) * 2) + 20rem);
    }

    .flexible-slider__items {
      top: 0;
      transform: none;
    }

    .flexible-slider__controls {
      margin-top: $spacing;
    }
  }

  @include mq(m) {
    ::v-deep {
      .flexible-slider-container {
        height: 68rem;
      }
    }
  }

  @include mq(l) {
    ::v-deep .flexible-slider__controls {
      margin-top: $spacing * 1.5;
    }
  }
}

.prices-realizations__item {
  position: relative;
  display: flex;
  flex-direction: column;
  width: min-content;
  height: 100%;
}

.prices-realizations__item__picture {
  position: relative;
  margin-bottom: $spacing;

  ::v-deep {
    .picture {
      width: 80vw;
      height: calc(calc(80vw / 3) * 2);
    }

    .picture__container {
      position: relative;
      width: 100%;
      height: 100%;
    }

    img {
      @include image-fit();
    }

    .picture__caption {
      @extend %visually-hidden;
    }
  }

  @include mq(m) {
    ::v-deep {
      .picture {
        width: fit-content;
        height: 45rem;
      }

      .picture__container {
        width: fit-content;
      }

      .picture__caption {
        display: none;
      }

      img {
        position: static;
        width: auto;
        max-width: none;
        height: 100%;
      }
    }
  }
}

.prices-realizations__item__picture__icon {
  position: absolute;
  right: $spacing;
  bottom: $spacing;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 3rem;
  height: 3rem;
  color: $c-white;
  background: $c-mustard;
  border-radius: 50%;
  opacity: 0;
  transition: opacity 0.2s $ease-softer;

  .prices-realizations__item:hover & {
    opacity: 1;
  }
}

.prices-realizations__item__title {
  /* stylelint-disable property-no-vendor-prefix, value-no-vendor-prefix */
  display: -webkit-box;
  overflow: hidden;
  margin-bottom: $spacing;
  -webkit-line-clamp: 3;
  line-clamp: 3;
  text-overflow: ellipsis;
  -webkit-box-orient: vertical;
  /* stylelint-enable property-no-vendor-prefix, value-no-vendor-prefix */
}

.prices-realizations__item__info {
  @extend %ff-alt;

  display: flex;
  gap: $spacing * 0.5;
  align-items: center;
  flex-shrink: 0;
  font-size: 1.5rem;

  & + & {
    margin-top: $spacing * 0.6;
  }

  .icon {
    flex-shrink: 0;
    color: $c-mustard;
  }

  ::v-deep {
    em {
      @extend %ff-default;

      font-weight: 400;
    }
  }
}

.prices-realizations__item__link {
  @include get-all-space;

  span {
    @extend %visually-hidden;
  }
}
