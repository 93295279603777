
          @use 'sass:math';
          $env: 'production';
          @import '/var/build/src/styles/utils/index';
        




































































$bp: 'l';

.prices-services__gallery {
  @include mq(l) {
    ::v-deep .flexible-gallery {
      --gallery-height: 50rem;
    }
  }
}

.prices-services__list {
  @extend %list-nostyle;

  border-bottom: 1px solid $c-gray-lighter;

  @include mq($bp) {
    display: flex;
    flex-wrap: wrap;
    gap: $spacing * 2.5 $spacing * 4;
  }
}

.prices-services__item {
  position: relative;
  padding: 0 0 $spacing * 2 $spacing * 2;

  @include mq($bp) {
    flex: 40% 1 1;
    padding: 0 0 $spacing * 4.5 $spacing * 3.8;

    &:nth-last-child(n + 3) {
      padding-bottom: $spacing * 4;
      border-bottom: 1px solid $c-gray-lighter;
    }
  }
}

.prices-services__item__icon {
  position: absolute;
  top: 0;
  left: 0;
  color: $c-mustard;

  @include mq($bp) {
    width: 4.6rem;
    height: 4.6rem;
  }
}

.prices-services__item__title {
  @extend %ff-alt;
  @extend %fw-bold;

  margin-bottom: $spacing * 0.5;
  font-size: 2.4rem;

  @include mq($bp) {
    font-size: 3rem;
  }
}
