
          @use 'sass:math';
          $env: 'production';
          @import '/var/build/src/styles/utils/index';
        









































































$bp: 'm';

.prices-examples-inner {
  border-bottom: 1px solid $c-gray-lighter;

  @include mq($bp) {
    display: flex;
    gap: col(1, 12);
  }
}

.prices-examples__pictures {
  position: relative;
  margin: 0 col(2, 12) 0 col(2, 12);
  padding: $spacing * 2 0 0 col(1, 12);

  @include mq($bp) {
    display: flex;
    flex-direction: column;
    justify-content: center;
    flex: 1;
    margin: 0;
    padding: $spacing * 2 0 0 col(1, 12);
  }
}

.prices-examples__pictures__left,
.prices-examples__pictures__right {
  position: relative;
  overflow: hidden;
  border-radius: 1.5rem;
  box-shadow: $card-shadow;

  @include mq($bp) {
    border-radius: 2rem;
  }
}

.prices-examples__pictures__left {
  position: absolute;
  z-index: 1;
  top: 0;
  left: 0;
  width: col(3, 8);
  aspect-ratio: 2/3;

  @include mq($bp) {
    top: $spacing * -0.5;
    width: col(2.5, 6);
  }
}

.prices-examples__pictures__right {
  width: 100%;
  padding-bottom: 80%;
}

.prices-examples__content {
  margin-top: $spacing;

  @include mq($bp) {
    width: col(5, 12);
    margin-top: 0;
  }
}

.prices-examples__content__title {
  @extend %ff-alt;
  @extend %fw-bold;

  margin: $spacing 0;
  font-size: 3rem;
}

.prices-examples__content__price {
  margin-top: $spacing;
}
